import React, { useState, useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import "./App.css"
import Piano from "./Images/piano.jpg"
import music from "./Images/msc.png"
import pause from "./Images/ps.png"
import playBtn from "./Images/ply.png"
import PayFlutter from "./Components/pay/PayFlutter"
import close from "./Images/close.svg"
import { Howl } from "howler"

import Notification from './Components/Notification/Notification'
import Loading from './Components/Loading/Loading'

const App = () => {

    const [openPopUp, setOpenPopup] = useState(false)

    const closePopup = () => {
        setOpenPopup(false);
    }

    const [currentlyPlayingId, setCurrentlyPlayingId] = useState("p");
    const [musicPlayer, setMusicPlayer] = useState();

    const [songs, setSongs] = useState([]);

    // {
    //     "_id": 0,
    //     "title": "Hail! the day that sees Him rise",
    //     "description": "Alleluia christ is risen",
    //     "audioObj": {
    //       "id": "Audio/zuvu45r3elidowqqfmd4",
    //       "url": "https://res.cloudinary.com/dblrbr1y1/video/upload/v1680739918/Audio/zuvu45r3elidowqqfmd4.mp3"
    //     },
    //     "staffObj": {
    //       "id": "Staff/yjeue8rq7mveqw3jmccu",
    //       "url": "https://res.cloudinary.com/dblrbr1y1/image/upload/v1680739914/Staff/yjeue8rq7mveqw3jmccu.pdf"
    //     },
    //     "solfaObj": null,
    //     "minimumPrice": 500,
    //     "canDownloadAudio": true,
    //     "files": [
    //       "Staff",
    //       "Mp3"
    //     ]
    //   }
    const [loading, setLoading] = useState(true);
    const [showDownloadPage, setShowDownloadPage] = useState(false);

    const [downloadUrl, setDownloadUrl] = useState("#");

    // const apiUrl = "http://127.0.0.1:5000/"
    const apiUrl = "https://thatgreatcomposer-api.onrender.com/"


    useEffect(() => {

        fetch(apiUrl + "music/allCompositions")
            .then(res => (res.json()))
            .then(res => {
                setSongs(res)
                console.log(res)
                setLoading(false)
                // 
            })


    }, []);

    const clearedMessage = { name: "", message: "", subject: "", email: "" }

    const [inputData, setInputData] = useState(clearedMessage);

    // Destructure object into separate variable
    const { name, message, subject, email } = inputData;

    const handleInput = (e) => {
        let temp = { ...inputData }
        temp[e.target.name] = e.target.value
        setInputData({ ...temp })
    }

    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

    const Validate = () => {
        setErrorMsg("")
        if (name === "" || name === undefined) {
            setErrorMsg("The name field should not be left blank.")
            return false
        } else if (!re.test(String(email).toLowerCase())) {
            // Email text failed
            setErrorMsg("Do check the email address.")
            return false
        } else if (subject === "" || subject === undefined) {
            setErrorMsg("Please fill in the subject of discussion.")
            return false
        } else if (message === "" || message === undefined) {
            setErrorMsg("The message field is empty.")
            return false
        }

        return true
    }

    const [errorMsg, setErrorMsg] = useState("");

    const [isSendingMessage, setIsSendingMessage] = useState(false);
    const submitMessage = () => {
        // clear input box
        if (Validate()) {
            setIsSendingMessage(true)
            sendMessage()
        }
    }

    const sendMessage = () => {

        const data = {
            "name": name,
            "message": message,
            "subject": subject,
            "email": email
        }

        const formBody = Object.keys(data).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key])).join('&')
        const fetchOption = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
            },
            body: formBody.toString()
        }

        fetch(apiUrl + "sendMessage", fetchOption)
            .then(response => response.json())
            .then(data => {
                setInputData({ ...clearedMessage })
                document.getElementById("form").reset();
                AlertUser("Your message has been sent")
                setIsSendingMessage(false)
            }).catch(err => {
                setIsSendingMessage(false)
                AlertUser("Failed to send Message")
            }
            )
    }

    const openDownloadManager = (title,description, price, id, fileType) => {
        // console.log(title, price);
        // console.log(songs);
        // setToken("");
        // setError("")

        setSongDetails(
            {
                name: title,
                description: description,
                minimumPrice: price,
                id: id,
                fileType: fileType
            }
        )
        setPaymentData({ formattedPrice: price, price: price, validatedEmail: true });

        // AlertUser("Please enter a generous price")
        setOpenPopup(true);
    }

    //console.log(songs);
    // const [openDownloadById,setOpenDownloadById] = useState(null);
    // const [localTimeOut, setLocalTimeOut] = useState()

    const SingleSong = (props) => {


        return (
            <div key={props.id} className='single-song-card'>
                <div>
                    <img onClick={() => { playOrPauseAudio(props.audioUrl, props.id) }} alt='play button' className={currentlyPlayingId === props.id ? 'pause-and-play' : 'pause-and-play dim'} src={currentlyPlayingId === props.id ? pause : playBtn} />
                    <div>
                        <div className='song-title'>{props.title}</div>
                        <div className='song-details'>{props.description}</div>
                    </div>
                </div>
                <div className='card-download-parent'>

                    <div onClick={() => { openDownloadManager(props.title, props.description, props.minimumPrice, props.id, "Staff") }} className={"score-parts"}>
                        Buy Now
                    </div>

                </div>
            </div>
        )
    }

    const playOrPauseAudio = (url, id) => {
        if (currentlyPlayingId === "p") {
            setMusicPlayer(new Howl({
                autoplay: true,
                src: [url],
                html5: true,
                format: ["mp3"],
            }))

            setCurrentlyPlayingId(id);
        } else if (currentlyPlayingId === id) {
            // Pause the music
            musicPlayer.pause();
            setCurrentlyPlayingId("p")
        } else {
            musicPlayer.stop()
            setMusicPlayer(new Howl({
                autoplay: true,
                src: [url],
                html5: true,
                format: ["mp3"],
            }))
            setCurrentlyPlayingId(id);
        }

    }

    try {
        musicPlayer.on("end", function () {
            setCurrentlyPlayingId("p")
        })
    } catch {

    }

    const [imageHasLoaded, setImageHasLoaded] = useState(false)

    const [songDetails, setSongDetails] = useState()

    const [paymentData, setPaymentData] = useState();

    const downloadedFile = () => {
        setShowDownloadPage(false);
        setLoading(false);
        closePopup();
    }

    const [notificationList, setNotificationList] = useState([]);

    const AlertUser = (message, time = 5000) => {
        setNotificationList([...notificationList, { message: message, time: time }])
    }

    const RemoveNotification = () => {
        let temp = [...notificationList]
        temp.shift()
        setNotificationList([...temp])
    }

    const contactServerAndVerify = (data) => {
        setLoading(true);
        console.log({...data, name:inputData.name, email:inputData.email, price:paymentData.price, title:songDetails.name, songId:songDetails.id})
        fetch(apiUrl + "pay/verify", {
            method: "POST", 
            headers: {
            "Content-Type": "application/json",
            },
            body: JSON.stringify({...data, name:inputData.name, email:inputData.email, price:paymentData.price, title:songDetails.name, songId:songDetails.id}), // body data type must match "Content-Type" header
        })
        .then(res => (res.json()))
        .then(res => {
            console.log(res)
            if (res.id){
                // transaction was a success
                setShowDownloadPage(true) // display a thank you
                setDownloadUrl(`${apiUrl}downloadsManager/download?sid=${res.id}`)
                setLoading(false);
            }
        }).catch((e) =>{

        })
    }


    const [paymentFormError,setPaymentFormError] = useState("");
    return (
        <div className={!loading ? 'App' : "App center"}>{
            loading ? <Loading /> :
                showDownloadPage ? <div className='download-page'>
                    <h1 className='fancy-font'>Thank You</h1>
                    <p>Your download link is ready, <a onClick={downloadedFile} download={true} href={downloadUrl}> Click here to download</a> </p>
                </div> :

                openPopUp ? (
                    <div className='pop-up'>
                        <div className='download-box'>
                            <img onClick={closePopup} className='close-icon' src={close} alt="close" />
                            <div className='top-download-box'>
                                <div className='download-box-pup-con'>
                                    <h1>{songDetails.name} (${paymentData.price})</h1>
                                    <p>{songDetails.description}</p>
                                </div>
                                <form className='payment-form'>
                                    <div className={paymentFormError !== "" ? 'error opened' : 'error'}>{paymentFormError}</div>
                                    <input className='down-input' onChange={e => handleInput(e)} name="name" placeholder='John Doe' />
                                    <input className='down-input' onChange={e => handleInput(e)} name="email" type="email" placeholder='joedoe@mail.com' />
                                   
                            </form>
                               <PayFlutter 
                                        contactServerToVerifyPayment = {contactServerAndVerify}
                                        err = {setPaymentFormError}
                                        name = {inputData.name}
                                        email = {inputData.email}
                                        amount = {paymentData.price}
                                        title = {songDetails.name}
                                        description = {songDetails.description}
                                    />
                      
                            </div>
                            <div className='download-text'>© 2024, Songs of the spirit, All rights reserved.</div>
                        </div>
                    </div>
                ):
                <React.Fragment>
                
                    {!imageHasLoaded && <Loading />}
                    <Notification
                        notifications={notificationList}
                        removeNotification={RemoveNotification}
                    />

                    <div className='main-section-con'>
                        <div className="main-section">
                            <div className='title-con'>
                                <img alt='Line Art' src={music} />
                                <div className='fancy-font'>John & Henshaw </div>
                                <img alt='Line Art' src={music} className='inverted-image' />
                            </div>
                            <div className='fancy-font sub-title'>"See Through our eyes"</div>
                            <div className='photos'>
                                <div className='shades neg'></div>
                                <img onLoad={() => setImageHasLoaded(true)} src={Piano} alt="Piano" />
                                <div className='shades'></div>
                            </div>
                            <div className='whoisJohn'>
                                <div className='sub-heading fancy-font'>Songs of the Spirit Book 1</div>
                                <p>
                                    Songs of the Spirit is a compilation of compositions primarily by  <a href='https://web.facebook.com/john.eifediyi/' rel="noreferrer" target='_blank' >John J. Eifediyi</a>  and <a href='https://www.youtube.com/@Heinshior' rel="noreferrer" target='_blank' >Henshaw O. Akhanoba.</a> It is a call to all christians; exploring the full spectrum of our emotion in response to faith. From the joy of Christ's birth to the deep sadness of His crucifixion and the glory of His resurrection, each composition resonates with the essence of His divine journey, employing us to immerse ourselves in the depths of faith, and to open your heart to every word, sound and silence as we offer solace, inspiration, and spiritual renewal with each note.
                                </p>
                                <p>
                                    Songs of the Spirit is a collection of 25 diverse compositions perfectly crafted for Christian worship and concerts alike. Whether in the sacred sanctuary or the grand concert hall, 'Songs of the Spirit' speaks with the universal language of faith, inspiring hearts and lifting spirits with rich divine melodies.
                                </p>
                                <p> Experience the entire collection of 'Songs of the Spirit' for just $199 through the link below. Alternatively, select individual pieces of your choice, starting at just $9.99 each. Start your journey of musical inspiration and spiritual upliftment today! </p>
                                <div onClick={() => { openDownloadManager(songs[songs.length-1].title, songs[songs.length-1].description, songs[songs.length-1].minimumPrice, songs[songs.length-1].id, "Staff") }} className='purchaseButton'>Buy Entire Collection - $199.99</div>
                                <p> Purchase a PDF of any song from our collection, starting at $9.99. </p>

                                <div className='song-card-con'>
                                    {
                                        songs.map(
                                            (Song, index) => (
                                                <SingleSong
                                                    key={index}
                                                    audioUrl={Song.audioObj.url}
                                                    title={Song.title}
                                                    description={Song.description}
                                                    files={Song.files}
                                                    id={Song._id}
                                                    minimumPrice={Song.minimumPrice}
                                                    staffName={Song.staffName}
                                                    solfaName={Song.solfaName}
                                                    audioName={Song.audioName}
                                                />
                                            )
                                        )
                                    }
                                </div>
                            </div>



                        </div>


                        <div className='stayConnected'>
                            <div className='sub-heading fancy-font'>Talk To Us</div>
                            <form id='form' className='contact-form'>
                                <div className={errorMsg !== "" ? 'error opened' : 'error'}>{errorMsg}</div>
                                <div className='name-and-mail'>
                                    <div>
                                        <label>Name</label>
                                        <input onChange={e => handleInput(e)} name="name" placeholder='John Doe' />
                                    </div>
                                    <div>
                                        <label>Email address</label>
                                        <input onChange={e => handleInput(e)} name="email" type="email" placeholder='joedoe@mail.com' />
                                    </div>
                                </div>
                                <div>
                                    <label>Subject</label>
                                    <input onChange={e => handleInput(e)} name="subject" placeholder='Give Feedback, Request a composition, Commission a project' />
                                </div>
                                <div>
                                    <label>Message</label>
                                    <textarea onChange={e => handleInput(e)} name="message" placeholder='Write your message here...'></textarea>
                                </div>

                                <div onClick={submitMessage} className='submit-button' type={'submit'} >{isSendingMessage ? "Please wait.." : "Send Message"}</div>
                            </form>
                        </div>
                    </div>
                
                </React.Fragment>
        }

        </div>
    )
}

export default App