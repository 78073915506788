import React, {useState, useEffect} from 'react'


const Notification = (props) => {
   
    const [openNotification, setOpenNotification] = useState(false);
   
    if (props.notifications.length > 0 && !openNotification) {
        setTimeout(() => {
          setOpenNotification(true)
        }, 500);
    }  
    //  console.log(props.notifications)

    useEffect(() => {
        if (openNotification){ // notification
            setTimeout(() => {
                setTimeout(() => { props.removeNotification()}, 250 )
                setOpenNotification(false)
            }, props.notifications[0] ? props.notifications[0].time : 5000);
        }
     }, [openNotification]);

    
     
    return(
        <div className = {"notification " + ((openNotification && props.notifications[0]) ? "opened" : "")} >
            {props.notifications[0] ? props.notifications[0].message  : ""}
        </div>

    )
}

export default Notification