import React, {useState} from 'react'
import { useFlutterwave , closePaymentModal} from 'flutterwave-react-v3';


const PayFlutter = (props) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const [loading,setLoading] = useState(false);
    const Validate = () => {
        props.err("")
        if (props.name === "" || props.name === undefined) {
            props.err("The name field should not be left blank")
            return false
        } else if (!re.test(String(props.email).toLowerCase())) {
            // Email text failed
            props.err("Enter a valid email address")
            return false
        } 

        return true
    }

    const config = {
        public_key: 'FLWPUBK-89e3638163a4622ce34bdb4d00f636b0-X',
        tx_ref: Date.now(),
        amount: props.amount,
        currency: 'USD',
        payment_options: 'card,mobilemoney,ussd',
        customer: {
          email: props.email,
          name: props.name,
        },
        customizations: {
          title: props.title,
          description: props.description,
          logo: 'https://st2.depositphotos.com/4403291/7418/v/450/depositphotos_74189661-stock-illustration-online-shop-log.jpg',
        },
      };
    
    const handleFlutterPayment = useFlutterwave(config);
  return (
    <button
    className='flutter-pay'
    onClick={() => {if (Validate()){
        setLoading(true)
      handleFlutterPayment({
        callback: (response) => {
            if (response.status === "successful"){
                // Transaction was a success
                props.contactServerToVerifyPayment({id:response.transaction_id})
            }
            closePaymentModal() // this will close the modal programmatically
        },
        onClose: () => {setLoading(false)},
      });
    }}}
  >
    {loading ?"..." : "Pay Now"}
  </button>
  )
}

export default PayFlutter